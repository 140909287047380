.disclaimer {
  border: 1px solid var(--separator);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.33;
}

.disclaimer svg {
  margin-right: 1rem;
  flex-shrink: 0;
}

.disclaimer .icon {
  color: var(--primary);
}

.disclaimer .warning {
  color: var(--warning);
  background: var(--warning-20);
  border-radius: 50%;
  padding: 0.25rem;
}
