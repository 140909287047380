.inventory {
  display: grid;
  grid-template: auto / 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;
}

.info {
  margin-top: 1rem;
}

.info li:not(:first-child) {
  margin-top: 0.5rem;
}

@media screen and (min-width: 552px) {
  .inventory {
    grid-template: auto / repeat(3, 1fr);
  }
}

@media screen and (min-width: 768px) {
  .inventory {
    grid-template: auto / repeat(4, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .inventory {
    grid-template: auto / repeat(5, 1fr);
  }
}

@media screen and (min-width: 1440px) {
  .inventory {
    grid-template: auto / repeat(6, 1fr);
  }
}

@media screen and (min-width: 1920px) {
  .inventory {
    grid-template: auto / repeat(8, 1fr);
  }
}

.itemLoading {
  composes: loading from "../../../../style/utils.css";
  padding: 60% 50%;
}

.rabbids {
  width: 10.5rem;
  position: absolute;
  bottom: -1rem;
}

.imageContainer {
  min-height: 260px;
}
