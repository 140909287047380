@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animation {
  animation: spin 1s steps(8) infinite;
}
