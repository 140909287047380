.container {
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  z-index: 1000;
  padding: 0 var(--side-gap);
  min-height: var(--bottom-nav-height);

  background: var(--white);
  box-shadow: 0 -16px 24px -20px rgba(0, 0, 0, 0.25);
}

.container button {
  /* RESET */
  background: unset;
  padding: unset;
  margin: unset;
  border: unset;
}

.container a,
.container button {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--grey-dark);
  text-decoration: none;
  font-size: 0.75rem;
  transition: color 200ms ease-out;
  padding: 0;
  margin: 0 0.625rem;
  box-sizing: border-box;
  border-top: 0.125rem solid transparent;
  -webkit-tap-highlight-color: transparent;
}

.container a.active,
.container button.active {
  color: var(--primary);
  border-top-color: var(--primary);
}

.container a:active {
  background-color: var(--primary-20);
}

.container a svg,
.container button svg {
  transition: opacity 200ms ease-out;
}

.container a svg.activeIcon,
.container button svg.activeIcon {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.container a.active svg:not(.activeIcon),
.container button.active svg:not(.activeIcon) {
  opacity: 0;
}

.container a.active svg.activeIcon,
.container button.active svg.activeIcon {
  opacity: 1;
}

.iconContainer {
  position: relative;
}
