.icon {
  border-radius: 0.25rem;
  background-color: var(--separator);
  box-shadow: inset 0 0.125rem 0.188rem 0 rgb(143 143 163 / 30%);
  width: 1.5rem;
  height: 1.5rem;
  color: var(--white);
  transition: background-color 120ms ease-out;
}

input[type="checkbox"]:hover + .icon {
  background-color: var(--primary-20);
}

.icon.checked {
  background-image: linear-gradient(45deg, var(--primary), #af52de),
    linear-gradient(to bottom, var(--success), var(--success));
}

input[type="checkbox"]:disabled + .icon {
  opacity: 0.4;
}
