.container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  overflow: hidden;
  text-decoration: none;
  color: var(--grey-dark);
  composes: card card-hover-raise from "../../style/utils.css";
}

.thumbnail {
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}

.container h4 {
  line-height: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  border-top: 1px solid var(--bg-light);
  flex-grow: 1;
}

.game {
  font-size: 0.5rem;
  font-weight: 600;
  line-height: 0.75rem;
  letter-spacing: 0.025rem;
  color: var(--grey);
  text-transform: uppercase;
}

.priceDeFi {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
}

.price {
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--grey-dark);
  margin-left: 0.5rem;
}

.tag {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}

.tokenCount {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  font-family: "Ubisoft Sans";
  font-size: 0.625rem;
  color: var(--grey);
  line-height: 1.4;
}

.tokenCount > strong {
  color: var(--grey-dark);
}
