.title {
  margin-bottom: 0.25rem;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.tags > * {
  margin-bottom: 0.5rem;
}

.tags > *:not(:last-child) {
  margin-right: 0.5rem;
}
