.root {
  --imageSize: 10.5rem;
}

.container {
  border-radius: 1rem 1rem 0 0;
  margin: auto;
  width: 100%;
  max-width: 768px;
  background: var(--bg-light);
  padding: 0 var(--side-gap) 1.5rem var(--side-gap);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  display: block;
  width: var(--imageSize);
  height: var(--imageSize);
  margin: calc(var(--imageSize) * -0.5) auto 0 auto;
}

.title {
  margin-bottom: 0.5rem;
  text-align: center;
}

.container p {
  margin: 0 0 1rem 0;
  text-align: center;
}
