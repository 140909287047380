.container {
  --side: 0.25rem;
  overflow: hidden;
  width: min(500px, calc(100vw - var(--side) * 2));
  height: min(800px, 100vh);
  margin-top: 2rem;
}

.iframe {
  border: none;
}

.error {
  text-align: center;
}
