/* SECTION 8 */
#section8 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#section8 > div {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: var(--max-layout-width);
  width: 100%;
  border-radius: 1rem;
  border: 1px solid var(--greenAccent);
  box-shadow: 0 0 1rem 0.0625rem rgb(255 255 255 / 50%);
  background-color: var(--bg-footer);
  padding: 1.5rem;
  box-sizing: border-box;
}

#section8 > div > div {
  max-width: 35rem;
}

#section8 h1 {
  margin-bottom: 1rem;
}

#section8 h1 > span {
  display: inline-block; /* fix gradient on safari */
  line-height: 1.125;
  padding-bottom: 0.3rem; /* fix text clip issue */
  background-image: linear-gradient(90deg, var(--green), var(--greenAccent));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: var(--green);
}

.content p {
  margin-block-start: 0.5rem;
  margin-block-end: 0;
}

@media screen and (min-width: 768px) {
  #section8 > div {
    padding: 4rem 1.5rem;
  }
}
