.container {
  display: inline-block;
  vertical-align: top;
  padding: 0.0625rem 0.25rem;
  border-radius: 0.125rem;

  font-size: 0.625rem;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;

  color: var(--grey-dark);
  background-color: var(--separator);
}

.success {
  color: var(--success);
  background: var(--success-20);
}

.warning {
  color: var(--warning);
  background: var(--warning-20);
}

.danger {
  color: var(--danger);
  background: var(--danger-20);
}

.primaryGradient {
  color: var(--white);
  background: linear-gradient(to right, var(--primary), var(--accent));
  font-weight: 600;
  box-shadow: 0 0.25rem 0.5rem -0.25rem rgba(88, 86, 222, 0.5);
}

.white {
  background: var(--white);
}

.black {
  color: var(--white);
  background-color: var(--white-20);
}
