.container {
  background-color: var(--bg-landing);
  color: white;
  overflow-x: hidden;
}

.appBar {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;

  padding-bottom: 0.875rem;
  background-color: transparent;
  transition: background-color 250ms ease;
  box-shadow: none;
}

.appBarBgColor {
  background-color: rgba(12, 9, 42, 0.7);
  backdrop-filter: blur(10px);
}

/* Fallback for appBarBgColor on Firefox as blur effect is not working by default */
@-moz-document url-prefix() {
  .appBarBgColor {
    background-color: rgba(12, 9, 42, 0.9);
  }
}

.main {
  margin: 0 auto;
  background-color: var(--bg-footer);
}

.main > div:first-child {
  position: fixed;
  height: 100vh;
  overflow: hidden;
  width: 100%;
}

.section {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  position: relative;
  padding: 7.5rem var(--side-gap) 2rem;
  scroll-snap-align: start;
  z-index: 1;
}

.title {
  font-size: 2.5rem;
  line-height: 1;
}

.subtitle {
  font-size: 1rem;
  line-height: 1.5rem;
}

.content {
  font-size: 1rem;
  line-height: 1.5rem;
}

.maxLayoutWidth {
  max-width: var(--max-layout-width);
  margin: 0 auto;
}

/* SHARED */
.highlight {
  composes: text-rainbow-horizontal from "../../style/utils.css";
}

.mobileOnly {
  display: flex;
}

.desktopOnly {
  display: none;
}

.fade:first-child {
  width: 100%;
}

a {
  color: inherit;
}

/* MOBILE SIZE */
@media screen and (min-width: 768px) {
  .title {
    font-size: 4rem;
    line-height: 1;
  }

  .subtitle {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .content {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .mobileOnly {
    display: none;
  }
}
