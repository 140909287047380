.section {
  margin-bottom: 1rem;
}

.section h3 {
  margin-bottom: 0.5rem;
}

.card {
  composes: card from "../../../../style/utils.css";
}

.ubisoftName {
  display: flex;
  align-items: center;
}

.ubisoftName > svg {
  margin-right: 0.25rem;
}

.creator {
  text-transform: uppercase;
  font-family: "Ubisoft Sans", sans-serif;
}

.userCard {
  padding: 1rem;
  display: grid;
  grid-template: auto / auto 1fr auto;
  gap: 1rem;
  align-items: center;
  position: relative;
}

.ownerList > .userCard + .userCard {
  margin-top: 0.5rem;
}

.userCard > .ownerTag {
  position: absolute;
  top: -0.5rem;
  right: 1rem;
}

.avatarContainer {
  position: relative;
}

.avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
}

.avatarContainer .sticker {
  position: absolute;
  width: 1rem;
  height: 1rem;
  bottom: -0.25rem;
  right: -0.25rem;
}

.ownerName {
  display: flex;
  justify-content: flex-start;
}

.rainbow {
  composes: text-rainbow-horizontal from "../../../../style/utils.css";
}

.historyName {
  color: var(--grey-dark);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
}

.historyDate {
  color: var(--grey-dark);
  font-weight: 600;
  text-align: right;
}

.historyTime {
  text-align: right;
}

.tabBar {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.rabbids {
  width: 16rem;
}

/* LOADING */
.descriptionLoading {
  composes: loading from "../../../../style/utils.css";
  height: 4rem;
  width: 100%;
}

.itemValueLoading {
  composes: loading from "../../../../style/utils.css";
  height: 1rem;
  width: 20ch;
}

.usernameLoading {
  composes: loading from "../../../../style/utils.css";
  height: 1.25rem;
  width: 15ch;
}

.dateLoading {
  composes: loading from "../../../../style/utils.css";
  height: 2rem;
  width: 10ch;
}
