/* SECTION 4 */
#section4 {
  padding: 12rem var(--side-gap);
  text-align: center;
}

@media screen and (min-width: 768px) {
  #section4 h3 {
    font-size: 2.5rem;
  }
}
