.container {
  all: unset;

  --size: 1.5rem;
  width: var(--size);
  height: var(--size);

  background: var(--primary-20);
  color: var(--primary);

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  cursor: pointer;

  transition: background-color 120ms ease-out, color 120ms ease-out;
}

.container:hover {
  background-color: var(--primary);
  color: var(--white);
}

.container:active {
  box-shadow: 0 0.25rem 0.25rem var(--black-30) inset;
}

.container:focus-visible:before {
  content: "";
  display: block;
  position: absolute;
  --distance: -0.125rem;
  left: var(--distance);
  right: var(--distance);
  top: var(--distance);
  bottom: var(--distance);
  border-radius: 50%;
  box-shadow: 0 0 0 0.0625rem var(--primary-50);
}

.container > * {
  --iconSize: 1rem;
  width: var(--iconSize);
  height: var(--iconSize);
}
