.container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: center / cover url(https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/bg.jpg) scroll;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-width: var(--min-layout-width);
}

@media screen and (min-width: 768px) {
  .container {
    background-image: url(https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/bgDesktop.jpg);
    background-attachment: fixed;
  }
}

.bottomFixed {
  position: fixed;
  width: 100%;
  margin: 0 auto;
  bottom: 0;
  z-index: 1000;
}
