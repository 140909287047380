.container {
  display: flex;
  background-color: var(--separator);
  border-radius: 1.25rem;
  height: 2.5rem;
  position: relative;
  padding: 0 0.125rem;
}

.container button {
  color: var(--primary);
  line-height: 1rem;
  font-size: 0.75rem;
  font-weight: 600;
  background: none;
  border: none;
  flex: 1;
  z-index: 1;
  cursor: pointer;
}

.indicator {
  position: absolute;
  top: 0.125rem;
  background-color: white;
  height: calc(100% - 0.125rem * 2); /* 100% minus the top and bottom padding */
  border-radius: 1.125rem;
  box-shadow: 0 4px 20px -8px rgba(88, 86, 222, 0.5);
}
