#section1 {
  padding-top: 5rem;
}

.topContainer {
  align-self: center;

  display: grid;
  grid-template: 1fr / 1fr;
  align-items: center;
  background-image: url(../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/bgDesktop.jpg);
  background-position: center;
  border-radius: 1rem;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem;
  box-sizing: border-box;
}

.title {
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.rabbids {
  display: none;
}

.gameEmptyRight {
  position: absolute;
  height: 100%;
  top: 0;
  left: calc(100% + 1rem);

  border-radius: 1rem 0 0 1rem;
  background: linear-gradient(to right, rgba(88, 86, 222, 0.2), transparent);
}

.slider {
  display: flex;
  position: relative;
  margin: 1rem 0 0;
  align-self: center;
  width: stretch;
  /* not added by autoprefixer */
  width: -moz-available;
}

.sliderLoading > div {
  width: 100%;
}

.link {
  width: 100%;
  text-decoration: none;
}

.gameLoading {
  border: 1px solid rgba(88, 86, 222, 0.2);
  border-radius: 1rem;
  composes: loading from "../../../../style/utils.css";

  background: center / 400%
    linear-gradient(-70deg, rgba(12 9 42 / 50%) 40%, rgba(255 255 255 / 7%), rgba(12 9 42 / 50%) 60%);
}

.game {
  width: 100%;
  height: 256px;
}

.game > div {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}

.grb {
  position: relative;
  display: flex;
  background: center / cover no-repeat url(../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/backgroundGRB.png);
  border-radius: 1rem;
}

.grb > div {
  transition: transform 1s ease;
}

.grb > div:hover {
  transform: scale(1.1);
}

.nomad {
  position: absolute;
  width: 367px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -54%);
}

.explore {
  display: flex;
  align-items: center;
  box-shadow: 0 0 0 1px var(--white) inset;
  margin: 1.5rem auto 0;
  width: fit-content;
  height: 2rem;
  border-color: white;
  background-color: rgba(28, 28, 28, 0.3);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  box-sizing: border-box;
}

.explore > span {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  text-decoration: none;
  margin-left: 0.5rem;
}

.desktopOnly {
  display: none;
}

@media screen and (min-width: 768px) {
  .topContainer {
    grid-template: 416px / 1.5fr 1fr;
    max-height: 416px;
    padding: 0 2rem 0 4rem;
    margin: 0 3rem;
  }

  .title {
    font-size: 3.5rem;
  }

  .rabbids {
    display: flex;
    width: 100%;
    max-height: 100%;
    margin-left: auto;
    max-width: unset;
    margin-left: 0;
    object-fit: contain;
  }

  .slider {
    margin: 1rem 3rem 0;
  }

  .game > div {
    width: 50%;
  }

  .grb {
    background-size: 100%;
    transition: background-size 500ms ease;
  }

  .grb:hover {
    background-size: 105%;
  }

  .grb > div {
    transition: none;
  }

  .grb > div:hover {
    transform: none;
  }

  .comingSoon * {
    color: var(--primary);
  }

  .nomad {
    left: unset;

    right: 0;
    transform: translate(-20%, -54%);
  }

  .explore {
    margin: 1.5rem 0 0;
  }

  .desktopOnly {
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .topContainer {
    padding: 0 2rem 0 6rem;
  }

  .title {
    font-size: 4.5rem;
  }

  .game {
    width: 752px;
  }

  .game > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    width: 50%;
  }

  .grb {
    width: 752px;
  }

  .gameEmptyRight {
    left: calc(752px + 1rem);
  }
}

@media screen and (min-width: 1440px) {
  .rabbids {
    transform: translateY(-2rem);
  }
}
