.container {
  text-align: center;
}

.picture {
  margin: auto;
  width: 4rem;
  height: 4rem;
  object-fit: contain;
  border-radius: 50%;
}

.title {
  composes: text-rainbow-horizontal from "../../style/utils.css";
}

.content {
  composes: paragraph from "../../style/typography.css";
  color: var(--grey-dark);
  margin: 0.5rem 0;
}

.input {
  display: grid;
  gap: 1rem;
  grid-template-columns: auto 1fr;
  align-items: center;
  text-align: left;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 1rem;
}

.link:active:visited {
  color: var(--grey-dark);
}

.action {
  margin: 1rem auto 0;
}

.error {
  composes: paragraph from "../../style/typography.css";
  margin: 0.5rem 0 1rem 0;
  color: var(--danger);
}
