.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container > img {
  width: 16rem;
  margin-top: calc(-8rem - 1.25rem);
  margin-bottom: -2rem;
}

@media screen and (min-width: 768px) {
  .container > img {
    margin-top: 0;
  }
}

.title {
  margin-bottom: 0.5rem;
}

.container > p {
  text-align: center;
  margin: 0 0 1rem 0;
}

.header {
  padding-bottom: 6.125rem;
}

.criteria {
  width: 100%;
  flex-grow: 1;
  margin-bottom: 1.5rem;
}

.criteria > li + li {
  margin-top: 1rem;
}

.danger {
  color: var(--danger);
}

.warning {
  color: var(--warning);
}
