.picture {
  display: block;
  margin: auto;
  border-radius: 50%;
  height: 6rem;
  width: 6rem;
}

.username {
  font-family: "Ubisoft Sans";
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  composes: text-rainbow-horizontal from "../../../../style/utils.css";
  width: fit-content;
  margin: auto;
  text-align: center;
}

.container span {
  display: block;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.0625rem;
  color: var(--grey-dark);
  text-transform: uppercase;
  width: fit-content;
  margin: auto;
}
