.productCard {
  max-width: 335px;
  margin: -1rem auto 2.5rem;
}

@media screen and (min-width: 768px) {
  .productCard {
    margin: 0 auto;
  }
}
