.link {
  text-decoration: none;
}

.container {
  composes: card from "../../../../style/utils.css";
  padding: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.interactive {
  composes: card-hover-raise from "../../../../style/utils.css";
}

.serialNumber {
  font-family: "Ubisoft Sans";
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 0.625rem;
  font-weight: bold;
  line-height: 1.4;
  color: var(--grey-dark);
}

.thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.thumbnail img {
  width: 100%;
}

.name {
  color: var(--grey-dark);
  line-height: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  border-top: 1px solid var(--bg-light);
  flex-grow: 1;
}

.game {
  display: flex;
  font-size: 0.5rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  color: var(--grey);
}

.tag {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}
