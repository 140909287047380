.container {
  color: var(--white);
  margin: 1rem var(--side-gap) 1rem;
  padding-bottom: 2rem;
  position: relative;
}

.logo {
  margin-bottom: 3.5rem;
  padding-top: 0.5rem;
  composes: link-scale from "../../style/utils.css";
}

.logo svg {
  height: 1.5rem;
  width: auto;
}

.container h1 {
  position: relative;
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 400;
  z-index: 1;
}

.play {
  text-transform: uppercase;
  composes: text-rainbow-horizontal from "../../style/utils.css";
}

.cta {
  margin-top: 1rem;
}

.nomad {
  position: absolute;
  bottom: 0;
  right: 0;
  height: calc(100% + var(--top-nav-height) + 1rem);
}

@media screen and (min-width: 768px) {
  .container {
    margin: 1rem var(--side-gap) 1rem;
    padding-bottom: 4rem;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    margin: 1rem 13.75rem 1rem;
    padding-bottom: 4rem;
  }
}
