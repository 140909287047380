:root {
  /* COLORS */
  --black: #1c1c1c;
  --black-30: rgba(28 28 28 / 30%);
  --black-20: rgba(28 28 28 / 20%);
  --black-10: rgba(28 28 28 / 10%);

  --white: #ffffff;
  --white-30: rgba(255 255 255 / 30%);
  --white-20: rgba(255 255 255 / 20%);
  --white-10: rgba(255 255 255 / 10%);

  --grey-dark: #333333;
  --grey: #73758e;
  --grey-light: #e5e5e5;
  --grey-caption: #67677e;
  --grey-30: rgba(115 117 142 / 30%);

  --primary: #5856de;
  --primary-10: rgba(88 86 222 / 10%);
  --primary-20: #deddfc;
  --primary-30: rgba(88 86 222 / 30%);
  --primary-50: rgba(88 86 222 / 50%);
  --accent: #bd4be5;
  --accent-10: rgba(189 75 229 / 10%);

  --green: #8ee54b;
  --greenAccent: #56dea5;

  --separator: #e7e7f6;

  --bg: #1c1c1c;
  --bg-light: #f3f3f8;
  --bg-footer: #0c092a;

  --success: #0abd8d;
  --success-20: rgba(10 189 141 / 20%);
  --warning: #ff9700;
  --warning-20: rgba(255 151 0 / 20%);
  --danger: #d4180b;
  --danger-20: rgba(212 24 11 / 20%);

  /* SPACING */
  --side-gap: 1.25rem;
  --bottom-nav-height: 3.75rem;
  --top-nav-height: 5rem;
  --min-layout-width: 320px;
  --max-layout-width: 1136px;
  --side-container-width: 50%;
  --side-content-width: 375px;
}

@media screen and (min-width: 1024px) {
  :root {
    --side-content-width: max(415px, 33%);
  }
}

@media screen and (min-width: 1920px) {
  :root {
    --max-layout-width: 1520px;
  }
}
