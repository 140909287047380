.verticalCenter {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading {
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
}

.spinner {
  margin-bottom: 1rem;
}

.invert .loading,
.invert .spinner {
  color: var(--black);
}
