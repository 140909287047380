.topupItem + .topupItem {
  margin-top: 1rem;
}

.rampPage {
  background-color: white;
  border-radius: var(--radius) var(--radius) 0 0;
}

.rampPage > div {
  margin-bottom: calc(var(--bottom-nav-height) + 1rem);
}

.content section {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content h3 {
  margin-bottom: 0.5rem;
}

.content p {
  color: var(--grey-dark);
  margin-top: 0;
  margin-bottom: 1rem;
}

.content ul {
  flex-grow: 1;
  margin-bottom: 2rem;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}
