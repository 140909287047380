.countdown {
  color: var(--white);
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1rem;
  display: grid;
  grid-template: auto / auto auto;
  gap: 0.125rem;

  border-radius: 0.125rem;
  background-color: var(--primary);
  background-image: linear-gradient(to right, var(--primary), var(--accent));
  box-shadow: 0 0.25rem 0.5rem -0.25rem rgba(88, 86, 222, 0.5);
  padding: 0.125rem 0.25rem;
}

.timer {
  display: grid;
  grid-template: auto / repeat(6, auto);
  gap: 0.125rem;
}

.timer > span {
  font-weight: 600;
}

.pulse {
  animation: pulse 0.5s infinite alternate ease-out;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}
