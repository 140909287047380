.gameTitle {
  margin-bottom: 1rem;
  margin-right: 0.75rem;
}

.info {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1.125rem;
  align-items: center;
}

.info.twoColumns {
  grid-template-columns: auto 1fr;
}

.info > svg {
  width: 2.688rem;
  height: 2.688rem;
}

@property --gradiant-angle {
  syntax: "<angle>";
  initial-value: -45deg;
  inherits: false;
}

.card {
  /* reset */
  background-color: transparent;

  --border-radius: 0.25rem;
  position: relative;
  composes: card from "../../../style/utils.css";
  text-decoration: none;
  color: var(--grey-dark);
}

.card.hightlight::before {
  --offset: 0;
  --blur: 16px;
  content: "";
  display: block;
  z-index: 0;
  pointer-events: none;
  position: absolute;
  top: var(--offset);
  left: var(--offset);
  right: var(--offset);
  bottom: var(--offset);
  background-image: linear-gradient(var(--gradiant-angle, -45deg), var(--primary), var(--accent), var(--primary));
  border-radius: calc(var(--border-radius));
  filter: blur(var(--blur));
  animation: spin 4s linear infinite;
}

.card.hightlight::after {
  --offset: -1px;
  --blur: 0;
  content: "";
  display: block;
  z-index: 0;
  pointer-events: none;
  position: absolute;
  top: var(--offset);
  left: var(--offset);
  right: var(--offset);
  bottom: var(--offset);
  background-image: linear-gradient(var(--gradiant-angle, -45deg), var(--primary), var(--accent), var(--primary));
  border-radius: calc(var(--border-radius) + 0.25px);
  filter: blur(var(--blur));
  animation: spin 4s linear infinite;
}

@keyframes spin {
  0% {
    --gradiant-angle: 0deg;
  }
  100% {
    --gradiant-angle: 360deg;
  }
}

.card > .descriptionCard {
  position: relative;
  z-index: 1;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.visual {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  border-radius: var(--border-radius) var(--border-radius) 0 0; /* force border radius for safari mobile */
  overflow: hidden;
  background-color: var(--black);
}

.visual.withBackground {
  background-color: var(--black);
}

.description {
  position: relative;
  padding: 1rem;
  background-color: var(--white);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.middleContainer > div:first-child {
  font-size: 0.75rem;
  color: var(--grey);
}

.title {
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--grey-dark);
}

.title small {
  font-size: 0.625rem;
}

.iconButton {
  margin-left: 0.5rem;
}

.title > div {
  display: flex;
}

.rightContainer {
  text-align: right;
}

.priceFiat {
  font-size: 0.75rem;
  line-height: 1rem;
}

.maxInstances {
  font-weight: 400;
  color: var(--grey);
}

.sticker {
  width: 3rem;
  height: 3rem;
  filter: drop-shadow(0 0.125rem 0.25rem rgba(88, 86, 222, 0.3));
  object-fit: contain;
}

.pending {
  margin-bottom: 1rem;
}

.upcomingDate {
  display: block;
}

.tag {
  position: absolute;
  top: -0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link {
  text-decoration: none;
}

.upcomingTag {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  pointer-events: none;
  z-index: 1;
}

/* LOADING */
.cardLoading {
  --border-radius: 0.25rem;
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0 1rem 1rem -1.5rem rgba(88, 86, 222, 0.5);
}

.imageLoading {
  composes: loading from "../../../style/utils.css";
  height: 20rem;
}

.nameLoading {
  composes: loading from "../../../style/utils.css";
  margin-top: 0.5rem;
  width: 20ch;
  height: 1.5rem;
}

.gameTitleLoading {
  composes: loading from "../../../style/utils.css";
  margin-top: 0.5rem;
  width: 15ch;
  height: 1rem;
  margin-bottom: 1rem;
}

.infoLoading {
  composes: loading from "../../../style/utils.css";
  margin-top: 0.5rem;
  width: 100%;
  height: 3rem;
  margin-bottom: 1rem;
}
