.header {
  position: relative;
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain, cover;
  background-position: center, center;
  background-repeat: no-repeat;
}

@media screen and (min-width: 768px) {
  .header {
    height: 21rem;
    background-position: 76% center, center;
  }
}

.homeLink {
  position: absolute;
  top: 1.125rem;
  left: 1.25rem;
}

.fullScreen {
  max-width: 100% !important;
}

.logo {
  max-width: 16rem;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .logo {
    max-width: 30rem;
  }
}

.foreground {
  position: absolute;
  height: 100%;
  z-index: 0;
  left: 50%;
}

.badge {
  --width: 11rem;
  background-color: var(--black);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  width: 11rem;
  height: 11rem;

  border-radius: 50%;
  border: 0.125rem solid var(--bg-light);
  overflow: hidden;

  margin-top: calc(var(--width) / -2 - 1.25rem);

  display: none;
}

@media screen and (min-width: 768px) {
  .badge {
    display: block;
  }
}

.badge > img {
  display: block;
  width: 100%;
  height: 100%;
}

.bodyHeader {
  display: grid;
  grid-template: auto / auto;
  justify-items: center;
  gap: 1rem;

  margin-bottom: 1.5rem;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.description > p {
  margin: 0.5rem 0 0 0;
}

@media screen and (min-width: 768px) {
  .bodyHeader {
    grid-template: auto / auto 1fr auto;
    align-items: flex-start;
  }

  .description {
    align-items: flex-start;
    text-align: left;
  }
}

.marketplaceLinks {
  display: grid;
  grid-template: auto / auto;
  justify-content: center;
  gap: 1rem;
}

.marketplaceLinks > a {
  min-width: 9rem;
  background: transparent;
  box-shadow: 0 0 0 1px var(--primary) inset;
}

.saleGrid {
  display: grid;
  gap: 1rem;
  grid-template: auto / 1fr;
}

@media screen and (min-width: 768px) {
  .saleGrid {
    grid-template: auto / repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .saleGrid {
    grid-template: auto / repeat(3, 1fr);
  }
}

@media screen and (min-width: 1920px) {
  .saleGrid {
    grid-template: auto / repeat(4, 1fr);
  }
}

/* LOADING */
.cardLoading {
  composes: card from "../../style/utils.css";
}

.descriptionLoading {
  padding: 1rem;
}

.imageLoading {
  composes: loading from "../../style/utils.css";
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  padding: 50%;
  box-sizing: border-box;
}

.nameLoading {
  composes: loading from "../../style/utils.css";
  margin-top: 0.5rem;
  width: 20ch;
  height: 1.5rem;
}

.gameTitleLoading {
  composes: loading from "../../style/utils.css";
  margin-top: 0.5rem;
  width: 15ch;
  height: 1rem;
  margin-bottom: 1rem;
}

.infoLoading {
  composes: loading from "../../style/utils.css";
  margin-top: 0.5rem;
  width: 100%;
  height: 3rem;
}
