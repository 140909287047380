/* SECTION 5 */
#section5 {
  display: flex;
  flex-direction: column;
  background: url(../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/bgDesktop.jpg) center / cover no-repeat;
  background-attachment: scroll;
  padding: 4rem var(--side-gap);
  justify-content: center;
}

#section5 h1 {
  margin-bottom: 2rem;
}

.content p {
  margin-block-start: 0.5rem;
  margin-block-end: 0;
}

.buttons {
  display: flex;
  margin-top: 1.5rem;
}

.buttons > a {
  width: 9rem;
  background: transparent;
  box-shadow: 0 0 0 1px var(--primary) inset;
}

.buttons > a span {
  display: flex;
}

.buttons > a:first-child {
  margin-right: 1rem;
}

@media screen and (min-width: 768px) {
  #section5 {
    background: fixed url(../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/wolfchestrigGRB.png) right / 720px no-repeat,
      fixed url(../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/bgDesktop.jpg) center / cover no-repeat;
  }

  #section5 > div {
    max-width: var(--max-layout-width);
    width: 100%;
    margin: auto;
  }

  #section5 > div > div {
    max-width: 464px;
  }
}

@media screen and (min-width: 1024px) {
  #section5 {
    background: fixed url(../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/wolfchestrigGRB.png) right / contain no-repeat,
      fixed url(../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/bgDesktop.jpg) center / cover no-repeat;
  }
}
