/* LAYOUT */
.bodyLayout {
  display: grid;
  grid-template: auto / 1fr;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  gap: 1rem;
}

@media screen and (min-width: 768px) {
  .bodyLayout {
    grid-template: auto / 1fr 22.25rem;
  }
}

@media screen and (min-width: 1024px) {
  .bodyLayout {
    grid-template: auto / 1fr 24rem;
  }
}

.columnGrid {
  display: grid;
  grid-template: auto / 1fr;
  gap: 1rem;
  align-content: flex-start;
  justify-content: center;
}

.card {
  composes: card from "../../style/utils.css";
  padding: 1rem 1rem 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info {
  composes: card from "../../style/utils.css";
  overflow: hidden;
}

/* LOADING */
.productCardLoading {
  composes: card from "../../style/utils.css";
  max-width: 335px;
  width: 100%;
  margin: 0 auto;
}

.header > .productCardLoading {
  margin: -1rem auto 2rem;
}

@media screen and (min-width: 768px) {
  .header > .productCardLoading {
    margin: 0 auto;
  }
}

.descriptionLoading {
  composes: card from "../../style/utils.css";
  padding: 1rem;
}

.infoLoading {
  composes: loading from "../../style/utils.css";
  width: 100%;
  height: 3rem;
}

.buttonLoading {
  composes: loading from "../../style/utils.css";
  width: 100%;
  height: 3rem;
  border-radius: 1.5rem;
}

.imageLoading {
  composes: loading from "../../style/utils.css";
  width: 100%;
  padding: 50%;
  box-sizing: border-box;
}

.nameLoading {
  composes: loading from "../../style/utils.css";
  margin-top: 0.5rem;
  width: 25ch;
  height: 1.5rem;
}

.gameTitleLoading {
  composes: loading from "../../style/utils.css";
  margin-top: 0.5rem;
  width: 20ch;
  height: 1rem;
  margin-bottom: 1rem;
}

.cardItemLoading {
  composes: loading from "../../style/utils.css";
  width: 10ch;
  height: 1rem;
}

.priceLoading {
  font-size: 1.5rem;
  composes: loading from "../../style/utils.css";
  height: 2rem;
  width: 6ch;
}

.bodyLoading > *:not(:first-child) {
  margin-top: 1rem;
}
