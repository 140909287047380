.container {
  margin-top: 1rem;
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  composes: card card-hover from "../../../../style/utils.css";
}

.container:hover {
  cursor: pointer;
}

.icon {
  display: flex;
  align-items: center;
  color: var(--primary);
  margin: 0 1rem;
}

.question {
  color: var(--primary);
  margin-bottom: 0.5rem;
}

.answer {
  /* RESET */
  margin: 0;

  margin-top: 0.5rem;
}

.response {
  composes: paragraph from "../../../../style/typography.css";
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
}

.response br {
  display: none; /* force one line under question title */
}

.response a {
  pointer-events: none;
  text-decoration: none;
  color: inherit;
}

.response > p {
  margin-block-start: 0;
  margin-block-end: 0;
}
