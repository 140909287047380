.container {
  composes: card from "../../../../style/utils.css";
}

.gameContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
  background-color: var(--white);
  border-bottom: 1px solid var(--separator);
}

.gameContainer > h4 {
  margin-bottom: 0.5rem;
}

.game {
  composes: text-rainbow-horizontal from "../../../../style/utils.css";
}

.achievement {
  background-color: var(--white);
}

.wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
}

.progression {
  position: relative;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  height: 100%;
}

.check {
  width: 2rem;
  color: var(--success);
  height: 100%;
}

.circle {
  width: 1rem;
  height: 1rem;
  background-color: transparent;
  border-radius: 50%;
  border: solid 0.125rem var(--grey-light);
}

.connectorTop {
  position: absolute;
  top: -0.0625rem;
  width: 0.125rem;
  height: calc(50% - 0.625rem); /* half of the container height minus the circle or icon radius */
  border-top: 0.125rem solid;
}

.connectorBottom {
  position: absolute;
  bottom: -0.0625rem;
  width: 0.125rem;
  height: calc(50% - 0.625rem); /* half of the container height minus the circle or icon radius */
  border-bottom: 0.125rem solid;
}

.connectorUncheck {
  background-color: var(--grey-light);
  border-color: var(--grey-light);
}

.connectorCheck {
  background-color: var(--success);
  border-color: var(--success);
}

.achievementInfo {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.disabled span {
  color: var(--grey);
}

.level {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.625rem;
  line-height: 0.875rem;
  letter-spacing: 0.063rem;
  color: var(--grey);
}

.description {
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--grey-dark);
  margin-top: 0.125rem;
}

.achieved {
  display: block;
  margin-top: 0.25rem;
  color: var(--success);
}

.playerLevel {
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  font-weight: 600;
}

/* LOADING */
.levelLoading {
  composes: loading from "../../../../style/utils.css";
  width: 11ch;
  height: 1rem;
}

.progressionLoading {
  composes: loading from "../../../../style/utils.css";
  background: center / 400% linear-gradient(90deg, var(--separator) 40%, rgba(231 231 246 / 25%), var(--separator) 60%);
  border-radius: 0;
  padding-bottom: 0.0625rem;
}
