ul.container {
  padding-left: 1rem;
}

ul.container > li {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  position: relative;
  min-height: 2.5rem;
  box-sizing: border-box;
}

li > .label {
  flex-grow: 1;
}

li > svg + .label,
li > .label + .content,
li > svg + .content {
  margin-left: 1rem;
}

.content {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

ul.container > li + li {
  border-top: 1px solid var(--separator);
}

ul.container > li > svg {
  color: var(--grey);
  flex-shrink: 0;
}

.disabled {
  color: var(--grey);
}
