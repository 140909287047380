.container {
  display: flex;
  flex-direction: column;
}

.container > p {
  margin: 0 0 0.5rem 0;
  color: var(--grey-dark);
}

.container > p > svg {
  margin-right: 0.25rem;
}

.container > p > svg,
.container > p > span {
  vertical-align: middle;
}

.container > a,
.container > button {
  margin: 0.5rem auto;
}

.title {
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 768px) {
  .container {
    min-width: 335px;
  }
}
