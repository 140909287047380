.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container > svg {
  height: 4rem;
  width: 4rem;
  color: var(--primary-20);
}

.title {
  text-align: center;
  composes: text-rainbow-horizontal from "../../style/utils.css";
}

.content {
  composes: paragraph from "../../style/typography.css";
  font-family: "Source Sans Pro";
  text-align: center;
  color: var(--grey-dark);
  margin-top: 0.5rem;
}

.content > p {
  margin-top: 0;
}

.content > p:last-child {
  margin-bottom: 0;
}

.confirm {
  margin-top: 1.5rem;
}

.cancel {
  margin-top: 1rem;
}
