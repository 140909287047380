.container {
  border-radius: 1rem 1rem 0 0;
  margin: auto;
  max-width: 768px;
}

.container.anchorRight {
  border-radius: 1rem 0 0 1rem;
  width: var(--side-content-width);
  max-width: unset;
}

.content {
  overflow: auto;
}

.container > header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey);
  border-bottom: 1px solid var(--separator);
  padding: 1rem var(--side-gap);
}

.container > header > svg,
.container > header > img {
  size: 1.5rem;
  width: var(--size);
  height: var(--size);
}

.title {
  composes: text-rainbow-horizontal from "../../style/utils.css";
}

.container > header > * + .title {
  margin-left: 0.5rem;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: var(--side-gap);
}
