.container {
  margin-bottom: 1rem;
  background-position: top;
  background-size: cover;
}

.content {
  position: relative;
  min-height: 4rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contentPrimaryLevel {
  margin: 0 var(--side-gap);
}

.centered {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.content h3,
.content h4 {
  text-align: center;
  color: var(--white);
}

.content button.back {
  all: unset;
  position: relative;
  display: block;
  color: var(--white);
  padding: 0.75rem;
  margin-left: 0.5rem;
  border-radius: 50%;
  background-color: transparent;
}

.content button.back svg {
  display: block;
}
