.title {
  margin-bottom: 0.5rem;
  composes: text-rainbow-horizontal from "../../../../../style/utils.css";
}

.loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.info {
  margin-top: 1.5rem;
}
