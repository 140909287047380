.container {
  composes: card from "../../../../style/utils.css";
}

.header {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rainbow {
  composes: text-rainbow-horizontal from "../../../../style/utils.css";
}

.body {
  border-top: 1px solid var(--separator);
}

.wrapper {
  padding: 1rem;
  border-top: 1px solid var(--separator);
}

.button {
  padding: 0.25rem 1rem;

  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.0625rem;
  margin: auto;
}

.language {
  text-transform: uppercase;
}

/* LOADING */
.cardItemLoading {
  composes: loading from "../../../../style/utils.css";
  width: 15ch;
  height: 1rem;
}
