.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container > h3 {
  display: flex;
  align-items: baseline;
}

span + small {
  margin-left: 0.5rem;
}

.container > h3 > small {
  font-family: "Source Sans Pro";
  color: var(--grey);
  font-weight: 400;
}
