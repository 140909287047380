.title {
  margin-bottom: 0.5rem;
}

.list {
  display: grid;
  grid-template: auto / repeat(2, 1fr);
  gap: 1rem;
}

@media screen and (min-width: 552px) {
  .list {
    grid-template: auto / repeat(3, 1fr);
  }
}

@media screen and (min-width: 768px) {
  .list {
    grid-template: auto / repeat(4, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .list {
    grid-template: auto / repeat(5, 1fr);
  }
}

.noResults {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--grey);
}

/* LOADING */
.itemCardLoading {
  composes: loading from "../../style/utils.css";
  height: 12rem;
}
