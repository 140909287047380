.title {
  margin-bottom: 0.5rem;
  composes: text-rainbow-horizontal from "../../../../style/utils.css";
}

.purchase .input {
  display: grid;
  gap: 1rem;
  grid-template-columns: auto 1fr;
  align-items: center;
  text-align: left;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 1rem;
}

.purchase h4 {
  text-transform: capitalize;
}

.button {
  margin: 1rem 0;
}

.walletCard {
  composes: card from "../../../../style/utils.css";

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem 0.75rem 1.5rem;
  color: var(--grey-dark);
  line-height: 1.3333333333;
}

.walletCard .logo {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
  margin-right: 1rem;
}

.walletCard .nameContainer {
  flex-grow: 1;
}

.walletCard .nameContainer > div {
  font-size: 0.75rem;
  color: var(--grey);
}

.walletCard .address {
  color: var(--grey);
  font-size: 0.75rem;
}

.walletItem :nth-child(3) {
  color: var(--grey);
  font-size: 0.75rem;
  text-overflow: ellipsis;
  max-width: 7rem;
  overflow: hidden;
}

.walletItem :nth-child(3) > a {
  text-transform: uppercase;
}

.walletItem > div > h4 + div {
  display: flex;
  align-items: center;
}

.walletItem strong {
  margin: auto 0.125rem;
}

.walletItem a {
  width: 100%;
  height: 2rem;
  font-size: 0.75rem;
}

/* LOADING */
.balancePlaceholder {
  height: 1rem;
  width: 5ch;
  composes: loading from "../../../../style/utils.css";
  margin: auto 0.125rem;
}

.walletItemPlaceholder {
  height: 3rem;
  width: 100%;
  composes: loading from "../../../../style/utils.css";
}

.loading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
