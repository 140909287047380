.description {
  position: relative;
  padding: 1rem;
  background-color: var(--white);
}

.info {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1.125rem;
  align-items: center;
  padding: 0.25rem 0;
}

.info.twoColumns {
  grid-template-columns: 1fr auto;
}

.info > svg {
  width: 2.688rem;
  height: 2.688rem;
}

.highlight {
  background-color: var(--bg-light);
  border-radius: 0.25rem;
  box-shadow: 0 1rem 1rem -1.5rem var(--primary-50);
  padding: 0.25rem 1rem 0.25rem 0.5rem;
}

.card {
  /* reset */
  background-color: transparent;

  composes: card from "../../../../../style/utils.css";
  border-radius: 0.5rem;
  overflow: hidden;
  text-decoration: none;
  color: var(--grey-dark);
}

.visual {
  width: 100%;
  display: block;
  border-radius: 0.5rem 0.5rem 0 0; /* force border radius for safari mobile */
  overflow: hidden;
}

.visual.withBackground {
  background-color: var(--black);
}

.middleContainer {
  display: flex;
  align-items: center;
}

.subtitle {
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--grey);
}

.title {
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--grey-dark);
}

.title small {
  font-size: 0.625rem;
}

.iconButton {
  margin-left: 0.5rem;
}

.title > div {
  display: flex;
}

.rightContainer {
  text-align: right;
}

.priceFiat {
  font-size: 0.75rem;
  line-height: 1rem;
}

.maxInstances {
  font-weight: 400;
  color: var(--grey);
}

.stickerContainer {
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  margin-right: 1rem;
}

.sticker {
  width: 3rem;
  height: 3rem;
  filter: drop-shadow(0 0.125rem 0.25rem rgba(88, 86, 222, 0.3));
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.iconClaimed {
  --size: 2rem;
  box-sizing: border-box;
  width: var(--size);
  height: var(--size);
  background-color: var(--success-20);
  border-radius: 50%;
  padding: 0.25rem;
  color: var(--success);

  margin-right: 1rem;
}

.upcomingDate {
  display: block;
}

.tag {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
  padding: 0.125rem 0.25rem;
}
