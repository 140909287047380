@use "sass:math";

.background {
  background: var(--bg-footer);
  position: relative;
}

$particleSize: 50vmin;
$animationDuration: 30s;
$amount: 10;
.background > span {
  width: $particleSize;
  height: $particleSize;
  border-radius: $particleSize;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-duration: $animationDuration;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  $colors: (#bd4be5, #5856de);
  @for $i from 1 through $amount {
    &:nth-child(#{$i}) {
      color: nth($colors, random(length($colors)));
      opacity: 0.2;
      top: random(100) * 1%;
      left: random(100) * 1%;
      animation-duration: math.div(random($animationDuration * 10), 10) * 1s + 10s;
      animation-delay: math.div(random(($animationDuration + 10s) * 10), 10) * -1s;
      transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
      $blurRadius: (random() + 0.5) * $particleSize * 0.5;
      $x: if(random() > 0.5, -1, 1);
      box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
    }
  }
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}
