.card {
  composes: card from "../../style/utils.css";
  border-radius: 0.5rem;
  margin: -1rem auto 2rem auto;
  width: min(335px, 90%);
}

.card > header {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1rem;
  align-items: center;
  padding: 1rem;
}

.title {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
  align-items: center;
}

.title > svg {
  width: 1.5rem;
  height: 1.5rem;
}

.providerName {
  composes: text-rainbow-horizontal from "../../style/utils.css";
  width: max-content;
}

.card > header + .body {
  border-top: 1px solid var(--separator);
  padding: 1rem;
}

.card .body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card .body > h2 > small {
  font-size: 1rem;
  font-weight: 400;
}

.addressSection {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 0.5rem;
  align-items: center;
}

.address {
  display: flex;
  flex-direction: column;
}

.address > span {
  font-family: "Source Sans Pro";
  font-size: 0.75rem;
  color: var(--grey);
  line-height: 1rem;
}

.walletAddressTag {
  /* remove padding in order to align tag and following span in div */
  padding-left: 0;
}

.copy {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.balancePlaceholder {
  height: 2rem;
  width: 3ch;
  margin: 0.25rem 0;
  composes: loading from "../../style/utils.css";
}

.balanceFiat {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--grey);
}

.buttonGroup {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.body {
  display: flex;
  flex-direction: column;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.logo {
  width: 1.5rem;
  height: 1.5rem;
}

@media screen and (min-width: 768px) {
  .card {
    width: 356px;
  }
}

@media screen and (min-width: 1024px) {
  .card {
    width: 584px;
  }
}
