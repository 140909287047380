.container {
  /* reset */
  border: unset;
  text-align: unset;
  padding: unset;
  width: 100%;
  min-height: 4rem;
  font-size: unset;
  font-family: "Source Sans Pro";

  composes: card from "../../style/utils.css";

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem 0.75rem 1.5rem;
  box-sizing: border-box;
  position: relative;

  color: var(--grey-dark);
  line-height: 1.3333333333;
  text-decoration: none;
}

.interactive {
  transition: transform 200ms ease-out, box-shadow 200ms ease-out;
}

.interactive:hover:not(:disabled) {
  box-shadow: 0 24px 24px -24px rgba(88, 86, 222, 0.25);
}

.interactive:hover:not(:disabled) .chevron {
  transform: translate3d(0.5rem, 0, 0);
}

button.container {
  cursor: pointer;
}

.container > * {
  flex-shrink: 0;
}

.container .nameContainer {
  flex-grow: 1;
  flex-shrink: 1;
}

.container > * + .nameContainer {
  margin-left: 1rem;
}

.container .nameContainer .title {
  font-weight: 600;
}

.container .nameContainer .tag {
  position: absolute;
  top: -0.5rem;
  right: 1rem;
}

.container .nameContainer .description {
  font-size: 0.75rem;
  color: var(--grey-dark);
}

.container .chevron {
  color: var(--primary);
  transition: transform 200ms ease-out;
}

.disabled {
  border: 1px solid var(--separator);
  background: transparent;
  box-shadow: none;
}
