.img {
  margin-top: 1rem;
  width: 100%;
}

.category {
  margin-top: 2rem;
}

.category > li:first-child {
  font-family: "Ubisoft Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}
