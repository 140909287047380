/* SECTION 7 */
#section7 {
  justify-content: center;
}

#section7 > div:first-child {
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  #section7 > div:first-child {
    max-width: 1024px;
  }
}
