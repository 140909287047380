.productCard {
  max-width: 335px;
  margin: -1rem auto 2.5rem;
}

@media screen and (min-width: 768px) {
  .productCard {
    margin: 0 auto;
  }
}

.count {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.description {
  padding: 1rem;
}

.button {
  margin-top: 1rem;
}

/* LOADING */
.cardLoading {
  composes: card from "../../style/utils.css";
  border-radius: 0.5rem;
  width: 335px;
  margin: -1rem auto 2.5rem;
}

@media screen and (min-width: 768px) {
  .cardLoading {
    margin: 0 auto;
  }
}

.imageLoading {
  composes: loading from "../../style/utils.css";
  height: 20rem;
}

.nameLoading {
  composes: loading from "../../style/utils.css";
  margin-top: 0.5rem;
  width: 20ch;
  height: 1.5rem;
}

.gameTitleLoading {
  composes: loading from "../../style/utils.css";
  margin-top: 0.5rem;
  width: 15ch;
  height: 1rem;
  margin-bottom: 1rem;
}

.infoLoading {
  composes: loading from "../../style/utils.css";
  margin-top: 0.5rem;
  width: 100%;
  height: 3rem;
  margin-bottom: 1rem;
}

.sectionLoading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.descriptionLoading {
  composes: card from "../../style/utils.css";
  padding: 1rem;
}

.buttonLoading {
  composes: loading from "../../style/utils.css";
  width: 100%;
  height: 3rem;
  border-radius: 1.5rem;
}

.bodyLoading > *:not(:first-child) {
  margin-top: 1rem;
}
