.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--white);

  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
  text-align: center;
}

.message {
  margin-top: 1rem;
}
