.wrapper {
  display: flex;
  background-image: linear-gradient(72deg, var(--primary), var(--accent));
  color: var(--white);
  box-shadow: none;
}

.container {
  padding: 1rem 1.25rem;
  margin: auto;
  min-height: 4rem;
  max-width: var(--max-layout-width);
  box-sizing: border-box;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: 100%;
}

.message {
  flex: 1;
  font-size: 1rem;
  line-height: 1.5;
}

.link {
  text-decoration: none;
  color: var(--white);
  width: 100%;
  display: grid;
  justify-content: center;
}

.link svg {
  transition: transform 0.2s ease-out;
}

.link:hover:not(:disabled) svg {
  transform: translate3d(0.5rem, 0, 0);
}
