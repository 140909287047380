.content h3 {
  margin-bottom: 0.5rem;
}

.content p {
  color: var(--grey-dark);
  margin-top: 0;
  margin-bottom: 1rem;
}

.content section {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content ul {
  flex-grow: 1;
  margin-bottom: 2rem;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
}

.loading .spinner {
  margin-bottom: 1rem;
}
