.anchorBottom {
  height: 75vh;
}

ul.list > .item {
  padding: 1rem 1rem 1rem 0.5rem;
}

.listWrapper {
  display: flex;
  flex-direction: column;
}

.item:not(.disabled) {
  cursor: pointer;
}

.item:not(.disabled):hover .chevron {
  transform: translate3d(0.5rem, 0, 0);
}

.customLabel {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.serialNumber {
  font-weight: 600;
  margin-left: 0.5rem;
}

.chevron {
  display: flex;
  color: var(--primary);
  transition: transform 200ms ease-out;
}

.tag {
  display: flex;
}

.loadMore {
  margin: 1rem auto;
}

/* LOADING */
.cardItemLoading {
  composes: loading from "../../style/utils.css";
  width: 10ch;
  height: 1rem;
}

.labelItemLoading {
  composes: loading from "../../style/utils.css";
  width: 20ch;
  height: 1rem;
}

.error {
  text-align: center;
}
