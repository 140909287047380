.container {
  width: 100%;
  min-height: 100%;
  height: 100%;
  position: relative;
  flex-grow: 1;
}

.containerRow {
  display: grid;
  grid-template-columns: 1fr var(--side-container-width);
  grid-template-rows: 1fr;
}

.containerRow .bodyWrapper {
  margin: 0;
  border-radius: 0;
  align-items: center;
  justify-content: center;
}

.containerRow .body {
  padding: 1rem var(--side-gap);
  width: 100%;
  max-width: 335px;
}

@media screen and (min-width: 1024px) {
  .containerRow .body {
    padding: 1rem 2.5rem;
    max-width: 415px;
  }
}

@media screen and (min-width: 1440px) {
  .containerRow .body {
    max-width: 492px;
  }
}

.containerColumn {
  display: flex;
  flex-direction: column;
}

.containerColumn .header,
.containerColumn .body {
  margin: 0 auto;
  max-width: var(--max-layout-width);
  width: 100%;
  box-sizing: border-box;
}

.body {
  padding: calc(var(--radius) * 1.25) var(--side-gap) 4.75rem var(--side-gap);
}

@media screen and (min-width: 768px) {
  .body {
    padding-bottom: 1rem;
  }
}

.headerWrapper,
.bodyWrapper {
  display: flex;
  width: 100%;
}

.headerWrapper {
  align-items: center;
  justify-content: center;
}

.bodyWrapper {
  --radius: 1rem;
  margin-top: calc(var(--radius) * -1);
  border-radius: var(--radius) var(--radius) 0 0;
  background: var(--bg-light);
  position: relative;
  flex-grow: 1;
}

.ubisoftFooter {
  grid-column-start: 1;
  grid-column-end: 3;
}

.overlap {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.bottomPadding {
  margin-bottom: 1rem;
}

.withBackground {
  background: var(--black-30);
}
