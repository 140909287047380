.container {
  /* reset */
  background: none;
  border: none;
  padding: 0;
  width: fit-content;

  position: relative;
  background: var(--primary-20);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  letter-spacing: 0.0625em;
  padding: 0.75em 1.5em;
  border-radius: 1.5em;
  color: var(--primary);
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  transition: background-color 120ms ease-out;

  --hoverDuration: 0.5s;
  --hoverScaleDelay: 0.25s;
}

.container:hover:not(.disabled):not(.invert):not(:active) {
  color: var(--white);
  background-color: var(--primary);
}

.container:not(.disabled) {
  cursor: pointer;
}

.container:active:not(.disabled):not(.invert) {
  box-shadow: 0 0 0 1px var(--primary) inset, 0 0.25rem 0.25rem 0 var(--black-30) inset;
}

.container:focus-visible {
  outline: none;
}

.container:focus-visible:before {
  --distance: -0.125rem;
  content: "";
  position: absolute;
  left: var(--distance);
  top: var(--distance);
  right: var(--distance);
  bottom: var(--distance);
  border-radius: 99999px;

  box-shadow: 0 0 0 1px var(--primary-50);
}

.labelContainer {
  overflow: hidden;
}

.label {
  position: relative;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.0625em;
  text-transform: uppercase;
  composes: text-rainbow-horizontal from "../../style/utils.css";
}

.labelHover {
  font-size: 1em;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.0625em;
  text-transform: uppercase;

  position: absolute;
  top: 0;
  transform: translate3d(0, 100%, 0);
}

.container:hover:not(.disabled):not(.invert):not(:active) .label {
  background-image: none;
  background-color: var(--white);
}

.label > * {
  display: block;
}

.withIcon .labelContainer {
  margin-left: 0.5rem;
}

.invert {
  color: var(--white);
  box-shadow: none;
  background: none;
}

.invert > * {
  position: relative;
}

.invert::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 1.5em;
  background-image: linear-gradient(72deg, var(--primary), var(--accent));

  transition: transform 0.2s ease-in-out var(--hoverScaleDelay);
}

.invert:hover:not(.disabled)::before {
  transform: scale(1.05);
}

.invert:active:not(.disabled) {
  box-shadow: 0 0.25rem 0.25rem 0 var(--black-30) inset;
}

.invert * {
  background-clip: none;
  -webkit-text-fill-color: initial;
}

.disabled {
  background: var(--grey-30);
}

.disabled .label {
  font-weight: 400;
}

.disabled * {
  background-clip: none;
  -webkit-text-fill-color: initial;
  color: var(--grey);
}

.small {
  padding: 0.25rem 1rem;
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.063rem;
  min-height: 2rem;
}

.fullWidth {
  width: 100%;
}

.container span {
  display: block;
}

.container > span {
  overflow: hidden;
}

.container.invert:not(.disabled) {
  transition: transform var(--hoverDuration) cubic-bezier(0.9, 0, 0.1, 1);
}

.invert .label,
.labelHover {
  transition: all var(--hoverDuration) cubic-bezier(0.9, 0, 0.1, 1);
}

.container.invert:hover:not(.disabled) .label {
  transform: translate3d(0, -100%, 0);
}

.container.invert:hover:not(.disabled) .labelHover {
  transform: translate3d(0, 0, 0);
}
