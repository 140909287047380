.container {
  position: relative;
  box-sizing: border-box;
  color: var(--white);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes shrink {
  to {
    stroke-dashoffset: 180;
  }
}

.animation {
  display: block;
  transform: rotate(0);
  stroke-dashoffset: 25;
  stroke-dasharray: 200;
  animation: spin 1s linear infinite, shrink 1.5s ease-in-out infinite alternate;
}

.logo {
  --size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--size);
  height: var(--size);
  margin-top: calc(var(--size) * -0.5);
  margin-left: calc(var(--size) * -0.5);
}

.logo.contrast {
  color: var(--grey-dark);
}
