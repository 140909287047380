.container {
  --max-width: 375px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  box-sizing: border-box;
  height: 100%;
  padding: 1.5rem var(--side-gap) calc(var(--bottom-nav-height) + 1.5rem) var(--side-gap);
  color: var(--white);
  min-height: 100%;
}

@media screen and (min-width: 768px) {
  .container {
    padding-bottom: 1.5rem;
  }
}

.container p {
  text-align: center;
  max-width: var(--max-width);
  z-index: 0;
}

.centerContent {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.container h3 {
  z-index: 0;
}

.inFront {
  z-index: 0;
}

.sticker {
  width: 6rem;
  height: 6rem;
  margin-bottom: 1rem;
  z-index: 0;
}

.itemName {
  font-weight: 600;
}

p.email {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-block-start: 1rem;
  margin-block-end: 0;
  z-index: 0;
}

.button {
  margin-top: 1rem;
  max-width: var(--max-width);
  z-index: 0;
}

@media screen and (min-width: 768px) {
  .button {
    width: auto;
  }
}

.logo {
  align-self: flex-start;
}

.logo svg {
  height: 1.5rem;
  width: auto;
}
