.card {
  composes: card from "../../style/utils.css";
  flex-direction: column;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.description {
  composes: paragraph from "../../style/typography.css";
  margin-block-start: 0;
  margin-block-end: 0.5rem;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  width: 100%;
}

.buttons > a:first-child {
  margin-right: 1rem;
}

.buttons > a span {
  display: flex;
}

.buttons > a {
  background: transparent;
  box-shadow: 0 0 0 1px var(--primary) inset;
}
