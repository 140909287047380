.list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  height: max-content;
}

.list > li {
  display: inline-block;
  margin: 0.25rem 0.5rem 0.25rem 0;
}

.miniList {
  list-style: circle;
  padding-left: 2rem;
  margin-top: 1rem;
}

.listitem {
  composes: paragraph from "../../style/typography.css";
  list-style: circle;
  margin-block-end: 0.5rem;
}

.response > .listitem {
  color: var(--grey-dark);
}

.listitem:first-child {
  margin-block-start: 0.5rem;
}

.listitem:last-child {
  margin-block-end: 1rem;
}

.content {
  margin-top: 1.25rem;
}

.section {
  margin-bottom: 2rem;
}

.img {
  margin-top: 1rem;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .list {
    width: 728px;
    margin: 0 auto;
  }

  .content {
    width: 728px;
    margin: 1.25rem auto 0;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 384px;
    gap: 1rem;
  }

  .list {
    width: unset;
    margin: 0;
  }

  .content {
    width: unset;
  }

  .popular {
    overflow: hidden;
    display: block;
  }
}

@media screen and (min-width: 1920px) {
  .container {
    grid-template-columns: 2fr 1fr;
  }
}
