.list {
  margin-bottom: 1.5rem;
}

.walletItem + .walletItem {
  margin-top: 1rem;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
}

.loading .spinner {
  margin-bottom: 1rem;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}
