.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.errorIcon {
  --size: 16rem;
  width: var(--size);
  height: var(--size);
  margin-top: calc(var(--size) * -0.5 - 1.25rem);
}

@media screen and (min-width: 768px) {
  .errorIcon {
    margin-top: 0;
  }
}

.rainbow {
  composes: text-rainbow-horizontal from "../../style/utils.css";
}

.button {
  margin-top: 1rem;
}

.spaceBetween {
  justify-content: space-between;
}

.noFlexGrow {
  flex-grow: 0;
}
