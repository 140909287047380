ul,
ol {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

button {
  font-family: "Source Sans Pro", sans-serif;
  color: var(--grey-dark);
}

html,
body,
#root {
  box-sizing: border-box;
}

html {
  background-color: #170947; /* avoid blank background when scrolling on mobile */
}

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--grey-dark);
}
