.wrapper {
  background-color: var(--bg-footer);
}

.container {
  margin: 0 auto;
  max-width: var(--max-layout-width);
  position: relative;
  padding: 2rem var(--side-gap);
  color: var(--white);
  box-sizing: border-box;
}

.container nav {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.container nav ul {
  display: flex;
  flex-wrap: wrap;
}

.container nav ul > li {
  margin-right: 2rem;
  padding: 0.5rem 0;
}

.container a,
.container button {
  color: var(--white);
  text-decoration: none;
}

.container button {
  all: unset;
  cursor: pointer;
}

.generalLinks {
  font-family: "Ubisoft Sans", sans-serif;
  font-weight: 700;
  line-height: 1.5rem;
}

.termsLinks {
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.copyright {
  margin-top: 1rem;
  font-size: 0.75rem;
}

.esrb img {
  margin-right: 0.5rem;
}

/* MOBILE SIZE */
@media screen and (max-width: 767px) {
  .container {
    text-align: center;
  }

  .container > nav {
    display: flex;
    flex-direction: column;
  }

  .container > nav:first-of-type {
    flex-direction: column-reverse;
  }

  .container nav ul {
    flex-direction: column;
    align-items: center;
  }

  .container nav ul > li {
    margin: 0 0 1rem 0;
  }
}
