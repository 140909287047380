@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;1,400;1,600&display=swap");

@font-face {
  font-family: "Ubisoft Sans";
  src: url("https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/fonts/UbisoftSans-Regular.woff2") format("woff2"),
    url("https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/fonts/UbisoftSans-Regular.woff") format("woff"),
    url("https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/fonts/UbisoftSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Ubisoft Sans";
  src: url("https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/fonts/UbisoftSans-Bold.woff2") format("woff2"),
    url("https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/fonts/UbisoftSans-Bold.woff") format("woff"),
    url("https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/fonts/UbisoftSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubisoft Sans", sans-serif;
  margin: 0;
}

h1 {
  font-size: 2.5em;
  line-height: 1.2;
}

h2 {
  font-size: 2em;
  line-height: 1.25;
}

h3 {
  font-size: 1.5em;
  line-height: 1.33333333;
}

h4 {
  font-size: 1em;
  line-height: 1.5;
}

.caption {
  font-size: 0.75em;
  line-height: 1.33333333;
}

.numbers {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.33333333;
}

.subtitle {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
}

.paragraph {
  font-size: 1rem;
  line-height: 1.5;
}
