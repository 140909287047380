.container {
  padding: 0.875rem var(--side-gap) 0.875rem var(--side-gap);
  min-height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  box-sizing: border-box;
}

.container h1 {
  line-height: 0;
}

.container > nav {
  display: none;
}

.cta {
  margin: 0.125rem 0;
}

.link {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  font-size: 0.75rem;
  text-decoration: none;
  cursor: pointer;

  background-color: var(--white-10);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;

  transition: background-color 150ms ease-out;
}

.link > span {
  margin-right: 0.25rem;
}

.link:hover:not(:disabled) {
  color: var(--white);
  background-color: var(--primary);
}

.invert .link {
  color: var(--primary);
  background-color: var(--primary-10);
}

.link > svg {
  margin-right: 0.25rem;
}

.button {
  all: unset;
  color: var(--white);
  border-radius: 999999px;
  box-shadow: inset 0 0 0 1px var(--white-30);
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  margin-left: 1.5rem;

  transition: background-color 150ms ease-out;
}

.invert .button {
  color: var(--primary);
  box-shadow: inset 0 0 0 1px var(--primary-20);
}

.button:hover,
.invert .button:hover {
  color: var(--white);
  background-color: var(--primary);
}

.button > svg,
.button > img {
  display: block;
}

.button > svg {
  margin-left: 1rem;
  width: 1rem;
  height: 1rem;
  transition: ease-out 200ms transform;
}

.flip {
  transform: scaleY(-1);
}

.button > img {
  width: 2rem;
  height: 2rem;
  margin: 0.125rem 0.125rem 0.125rem 0.5rem;
  border-radius: 50%;
}

.inFront {
  z-index: 3;
}

.menu {
  margin: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 8px -4px var(--primary-50);
  background-color: var(--white);
  min-width: 12rem;
}

.menuList {
  padding: 0.5rem;
  box-sizing: border-box;
}

.menuList + .menuList {
  margin-top: 1px;
  position: relative;
}

.menuList + .menuList::before {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  left: 0.5rem;
  top: -1px;
  right: 0;
  background: var(--separator);
}

.menuList > .item + .item {
  margin-top: 0.125rem;
}

.menuList > .item > a,
.menuList .item > button {
  all: unset;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.25rem;
  cursor: pointer;
}

.menuList > .item > a:hover,
.menuList .item > button:hover {
  background-color: var(--bg-light);
}

.icon {
  color: var(--primary);
  width: 1rem;
  height: 1rem;
}

.icon.danger {
  color: var(--danger);
}

@media screen and (min-width: 768px) {
  .container > nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
