.text-rainbow-horizontal {
  background-image: linear-gradient(90deg, var(--primary), var(--accent));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: var(--primary);
}

.text-rainbow-vertical {
  background: linear-gradient(180deg, var(--primary), var(--accent));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: var(--primary);
}

.card {
  border-radius: 0.25rem;
  box-shadow: 0 16px 16px -24px rgba(88, 86, 222, 0.5);
  background-color: var(--white);
}

.card-hover-raise {
  transition: transform 200ms ease-out, box-shadow 200ms ease-out;
}

.card-hover-raise:hover:not(:disabled) {
  transform: translate3d(0, -8px, 0);
  box-shadow: 0 24px 24px -24px rgba(88, 86, 222, 0.25);
}

.card-hover {
  transition: transform 200ms ease-out, box-shadow 200ms ease-out;
}

.card-hover:hover:not(:disabled) {
  box-shadow: 0 24px 24px -24px rgba(88, 86, 222, 0.25);
}

.emptyCard {
  border-radius: 0.25rem;
  border: 1px solid var(--separator);
}

.loading {
  background: center / 400% linear-gradient(-70deg, var(--separator) 40%, rgba(231 231 246 / 25%), var(--separator) 60%);
  border-radius: 0.25rem;
  animation: loading 2s ease infinite;
}

.link-scale {
  transition: all 200ms ease-in-out;
}

.link-scale:hover:not(:disabled) {
  transform: scale(1.05);
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
