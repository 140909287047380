/* SECTION 3 */
#section3 {
  background-image: url(../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/bgDesktop.jpg);
  background-position: center;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.5rem var(--side-gap) 0;
}

.sectionContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sectionContent h1 {
  font-size: 3rem;
  max-width: 408px;
}

.sectionContent h1 > span {
  padding-bottom: 0.3rem; /* fix text clip issue */
}

.sectionContent h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  width: max-content;
}

.sectionContent ul {
  margin-top: 4rem;
}

.listItem {
  display: flex;
}

.listItem > div:first-child {
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem;
}

.checkIcon {
  background-color: rgba(88, 86, 222, 0.2);
  padding: 4px;
  color: white;
  border-radius: 50%;
  box-sizing: border-box;
  margin: 0.25rem 0;
}

.verticalLine {
  background-color: rgba(88, 86, 222, 0.2);
  width: 1px;
  margin: auto;
  flex-grow: 1;
}

.sectionContent ul li p {
  max-width: 560px;
  margin-block-start: 0.5rem;
  margin-block-end: 4rem;
}

.sectionContent ul li p span {
  display: block;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 768px) {
  #section3 {
    padding-top: 4rem;
    background-attachment: fixed;
  }

  .sectionContent h1 {
    font-size: 4rem;
  }

  .listItem > div:first-child {
    margin-right: 3.5rem;
    margin-left: 8.5rem;
  }
}
