.card {
  composes: card from "../../../../style/utils.css";
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productCard {
  max-width: 335px;
  margin: 0 auto;
}

.productCardDescription {
  composes: card from "../../../../style/utils.css";
}

.thumbnail {
  width: 100%;
  height: auto;
  border-radius: 0.25rem;
}

.info {
  composes: card from "../../../../style/utils.css";
  overflow: hidden;
}

.creator {
  text-transform: uppercase;
  font-family: "Ubisoft Sans", sans-serif;
}

.sectionTitle {
  margin-bottom: 0.5rem;
}

.ubisoftName {
  display: flex;
  align-items: center;
}

.ubisoftName > svg {
  margin-right: 0.25rem;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--primary);
}

.sticker {
  --size: 2rem;
  width: var(--size);
  height: var(--size);
  filter: drop-shadow(0 0.125rem 0.25rem rgba(88, 86, 222, 0.3));
  margin-left: -0.5rem;
}
