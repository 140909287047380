.date {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: var(--grey-caption);
}

.content p {
  /* RESET */
  margin: 0;

  margin-top: 0.5rem;
  color: var(--grey-dark);
}

.content h4 {
  margin-top: 1.5rem;
  color: var(--primary);
}

.related {
  margin-top: 1.5rem;
  overflow: hidden;
}

.question {
  margin-top: 1rem;
  background: var(--white);
  padding: 1.25rem 1rem 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question:hover {
  cursor: pointer;
}

.icon {
  color: var(--primary);
}

.response a,
.response a:active {
  text-decoration-color: var(--primary);
  color: var(--primary);
}

.response a:visited,
.response a:hover {
  text-decoration-color: var(--grey-dark);
  color: var(--grey-dark);
}

@media screen and (min-width: 768px) {
  .container {
    display: grid;
    grid-template-columns: 584px;
    justify-content: center;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    grid-template-columns: 1fr 384px;
    gap: 1rem;
  }

  .loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .related {
    margin: 0;
  }
}

@media screen and (min-width: 1920px) {
  .container {
    grid-template-columns: 2fr 1fr;
  }
}

.answerContent {
  font-size: 1rem;
  line-height: 1.5;
}
