.column {
  flex-direction: column;
  width: 100%;
}

.column > *:not(:first-child) {
  margin-top: 1rem;
}

.soldout {
  box-shadow: 0 0 0 1px var(--danger) inset;
}

.soldout span {
  color: var(--danger);
}
