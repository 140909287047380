.card {
  composes: card from "../../../../style/utils.css";
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.priceWrapper {
  display: flex;
  flex-direction: column;
}

.price {
  color: var(--primary);
}

.priceWrapper > span {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--grey-dark);
}

.regular {
  font-weight: 400;
}

.soldout {
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  box-shadow: 0 0 0 1px var(--danger) inset;
  color: var(--danger);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
}

.soldoutFree span {
  color: var(--danger);
}
