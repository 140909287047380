.descriptionWrapper {
  margin-top: 0.75rem;
}

.container {
  margin-top: 1.5rem;
  display: grid;
  grid-template: auto auto / 1fr;
  gap: 1rem;
  align-items: flex-start;
}

@media screen and (min-width: 768px) {
  .container {
    grid-template: auto / repeat(2, 368px);
    justify-content: center;
  }
}

.userInfoWrapper {
  border-radius: 0.25rem;
}

.achievementWrapper {
  border-radius: 0.25rem;
  overflow: hidden;
}

.link {
  display: flex;
  color: var(--white);
  padding: 0.75rem;
  margin-right: -0.75rem;
}
