.container {
  --color: var(--primary-20);
  display: flex;
  align-items: center;
}

.container svg {
  color: var(--color);
}

.container .textContent {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1rem;
  background: var(--color);
  border-radius: 0 0.125rem 0.125rem 0;
  padding: 0.125rem 0.25rem;
  color: var(--primary);
}

.container .currency {
  font-weight: 400;
}
