.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.content {
  width: 100%;
  max-width: 415px;
}

.item + .item {
  margin-top: 1rem;
}

.danger {
  color: var(--danger);
}

.link {
  color: var(--grey-dark);
}

ul.horizontalList {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.version {
  width: 100%;
  max-width: 415px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
