.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container > a {
  margin-top: 1.5rem;
}
