.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
  flex-grow: 1;
  position: relative;
}

.title {
  font-family: "Source Sans pro";
  text-align: center;
}

.container button,
.container a {
  margin-top: 1.5rem;
}
