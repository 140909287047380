/* SECTION 6 */
#section6 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
}

#section6 div > a {
  margin: 1.5rem 0;
  font-size: 0.875rem;
}

#section6 img {
  max-width: 48rem;
  max-height: 48rem;
  object-fit: contain;
}

/* MOBILE SIZE */
@media screen and (min-width: 768px) {
  #section6 {
    max-width: var(--max-layout-width);
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
}
