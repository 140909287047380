.count {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.productCard {
  max-width: 335px;
  margin: 0 auto;
}

.description {
  composes: card from "../../style/utils.css";
  padding: 1rem;
  margin-bottom: 1rem;
}

.caption {
  color: var(--grey);
}

.editionDetails {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  align-items: center;
}

.sticker {
  width: 3rem;
  height: 3rem;
  filter: drop-shadow(0 0.125rem 0.25rem rgba(88, 86, 222, 0.3));
  object-fit: contain;
}

.maxInstances {
  font-weight: 400;
  color: var(--grey);
}

.thumbnail {
  width: 100%;
  border-radius: 0.25rem;
}

.info {
  margin-top: 1rem;
}

/* LAYOUT */
.bodyLayout {
  display: grid;
  grid-template: auto / 1fr;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  gap: 1rem;
}

@media screen and (min-width: 768px) {
  .bodyLayout {
    grid-template: auto / 1fr 22.25rem;
  }
}

@media screen and (min-width: 1024px) {
  .bodyLayout {
    grid-template: auto / 1fr 24rem;
  }
}

@media screen and (min-width: 1920px) {
  .bodyLayout {
    grid-template: auto / 2fr 1fr;
  }
}

.columnGrid {
  display: grid;
  grid-template: auto / 1fr;
  gap: 1rem;
  align-content: flex-start;
  justify-content: center;
}

/* LOADING */
.productCardLoading {
  composes: card from "../../style/utils.css";
  max-width: 335px;
  width: 100%;
  margin: 0 auto;
}

.header > .productCardLoading {
  margin: -1rem var(--side-gap) 2rem;
}

.descriptionLoading {
  padding: 1rem;
}

.card {
  composes: card from "../../style/utils.css";
  margin-bottom: 1rem;
}

.infoLoading {
  composes: loading from "../../style/utils.css";
  width: 100%;
  height: 3rem;
}

.buttonLoading {
  composes: loading from "../../style/utils.css";
  width: 100%;
  height: 3rem;
  border-radius: 1.5rem;
}

.imageLoading {
  composes: loading from "../../style/utils.css";
  width: 100%;
  padding: 50%;
  box-sizing: border-box;
}

.nameLoading {
  composes: loading from "../../style/utils.css";
  /* margin-top: 0.5rem; */
  width: 25ch;
  height: 1.5rem;
}

.gameTitleLoading {
  composes: loading from "../../style/utils.css";
  margin-top: 0.5rem;
  width: 20ch;
  height: 1rem;
  margin-bottom: 1rem;
}

.priceLoading {
  font-size: 1.5rem;
  composes: loading from "../../style/utils.css";
  height: 2rem;
  width: 6ch;
}
